import { Component } from "react"
import React from "react"
import arrowTop from "../images/arrow-top.png" 

export default class GoTop extends Component {

  goTop = () => {
    // document.getElementById("top").scrollIntoView({behavior: "smooth"});
    // Behavior "smooth" not working on Chrome/Chromium. So we call another time scrollIntoView.
    document.getElementById("top").scrollIntoView();
  }

  render() {
    return (
      <img onClick={this.goTop} className="go-top" src={arrowTop} alt="" />
    )
  }
}
