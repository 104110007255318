import { Link } from "gatsby"
import React from "react"
import whiteLogo from "../images/white-logo.png"

const Header = ({ goTop }) => (
  <header style={{background: `transparent`}}>   
    {
      goTop ?
      <img style={{width: '8em'}} src={whiteLogo} alt="" onClick={() => goTop.moveTo(1)} />
      :
      <Link to="/">
        <img style={{width: '8em'}} src={whiteLogo} alt="" />
      </Link>
    }
  </header>
)

export default Header
